import React, { useState } from "react";
import CustomInput from "../elements/Inputs/CustomInput";

const TestPage = () => {
    const [state, setState] = useState([
        {
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },
        {
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },
        {
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },
        {
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        },{
            office:"Office1",
            departments: [
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                },
                {
                    department:"Department1",
                    teams: [
                        {
                            team: "Team1"
                        },
                        {
                            team: "Team2"
                        },
                        {
                            team: "Team3"
                        },  
                    ]
                }
            ]
        }
    ])
    return(
        <div className="TestPage">
            {/* <div className="main">Структура компанії</div>
            <div className="main-line" ></div>
            <div className="offices">
                {
                    state.map((office)=>{

                        return(
                            <div className="office-container">
                                <div className="office">{office.office}</div>
                                <div className="line-from-office"></div>
                                {
                                    office.departments.length > 0 && (
                                        <div className="departments">
                                    {
                                        office.departments.map((department)=>{
                                            return(
                                                <div className="department-container">
                                                    <div className="department">{department.department}</div>
                                                    <div className="teams">
                                                        {
                                                            department.teams.map((team)=>{
                                                                return(
                                                                    <div className="team-container">
                                                                        <div className="team">{team.team}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                    )
                                }
                                
                            </div>
                        )
                    })
                }
            </div> */}

            <CustomInput 
                label={"Тест лабел"} 
            />
        </div>
    )
}

export default TestPage