import { Button } from "@mui/material";
import React, { useRef } from "react";
import withMarkBackImg from './../../../img/back-pdf-with-mark.png';

const CaseLikePDF = ({ caseData }) => {
    const pdfRef = useRef();

    if (!caseData) {
        return <p>Дані відсутні</p>;
    }

    const caseProfilePhoto = caseData?.meta?.profileImg?.link?.link || null;
    const { phone1, phone2, middle_name, first_name, last_name, id: case_id, email, happy_bd } = caseData.general;
    const { potreba, history, family_info, comment } = caseData.data;
    const name = `${middle_name || ""} ${first_name || ""} ${last_name || ""}`.trim();

    const handlePrint = () => {
        const printContent = pdfRef.current; // Отримуємо область друку
        const WindowPrint = window.open("", "_blank", "width=800,height=600");

        WindowPrint.document.write(`
            <html>
            <head>
                <title>Друк</title>
                <style>
                    @media print {
                        body {
                            margin: 0;
                            padding: 0;
                            box-sizing: border-box;
                        }
                        .print-area {
                            width: 100%;
                            page-break-inside: avoid;
                            background: url(${withMarkBackImg});
                        }
                        .page {
                            page-break-after: always;
                        }
                        .page:last-child {
                            page-break-after: auto;
                        }
                        .CaseLikePDF {
                        width: 794px;
    height: 1123px;
    overflow: hidden;
    box-shadow: 0 0 0 1px #000;
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 25px;
                        }
                    .photo img{
    width: 100%;
   }
    .CaseLikePDF-block .title{
     font-size: 22px;
    font-weight: 600;
    }
    .CaseLikePDF-block .description {
    font-size: 18px;
    margin-top: 15px;
    }
    .CaseLikePDF-left, .CaseLikePDF-right {
     display: flex;
    flex-direction: column;
    gap: 50px;}
                    }
                </style>
            </head>
            <body>
                ${printContent.outerHTML}
            </body>
            </html>
        `);

        WindowPrint.document.close();

        // Перевіряємо, чи всі зображення завантажилися
        const images = WindowPrint.document.querySelectorAll("img");
        const imageLoadPromises = Array.from(images).map((img) => {
            return new Promise((resolve, reject) => {
                if (img.complete) {
                    resolve(); // Якщо зображення вже завантажене
                } else {
                    img.onload = resolve; // Чекаємо на завантаження
                    img.onerror = reject; // Помилка завантаження
                }
            });
        });

        // Чекаємо завершення завантаження всіх зображень
        Promise.all(imageLoadPromises)
            .then(() => {
                WindowPrint.focus();
                WindowPrint.print();
               // WindowPrint.close();
            })
            .catch((error) => {
                alert("Помилка завантаження одного або кількох зображень. Друк не буде виконано.");
                console.error("Помилка завантаження зображень:", error);
                WindowPrint.close();
            });
    };

    return (
        <>
            <Button onClick={handlePrint}>Друк</Button>
            <div ref={pdfRef} className="CaseLikePDF print-area">
                <div className="CaseLikePDF-left">
                    {caseProfilePhoto && (
                        <div className="photo">
                            <img
                                src={caseProfilePhoto}
                                alt="Профіль"
                                onError={() => alert("Не вдалося завантажити зображення")}
                            />
                        </div>
                    )}
                    <div className="CaseLikePDF-block">
                        <div className="title">#{case_id} {name}</div>
                        
                    </div>
                    <div className="CaseLikePDF-block">
                        <div className="title">Дата народження</div>
                        <div className="description">{happy_bd ? happy_bd : "Не вказано"}</div>
                    </div>
                    {(phone1 || phone2 || email) && <div className="CaseLikePDF-block">
                        <div className="title">Контактні дані</div>
                        <div className="description">
                            <ul>
                                {phone1 && <li>Тел. {phone1}</li>}
                                {phone2 && <li>Тел. {phone2}</li>}
                                {email && <li>Email {email}</li>}
                            </ul>
                        </div>
                    </div>}
                </div>
                <div className="CaseLikePDF-right">
                    <div className="CaseLikePDF-block">
                        <div className="title">Сімейний стан</div>
                        <div className="description">{family_info ? family_info : "Не вказано"}</div>
                    </div>
                    <div className="CaseLikePDF-block">
                        <div className="title">Потреба / запит</div>
                        <div className="description">{potreba ? potreba : "Не вказано"}</div>
                    </div>
                    <div className="CaseLikePDF-block">
                        <div className="title">Історія сім'ї / особи</div>
                        <div className="description">{history ? history : "Не вказано"}</div>
                    </div>
                    <div className="CaseLikePDF-block">
                        <div className="title">Коментар</div>
                        <div className="description">{comment ? comment : "Не вказано"}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CaseLikePDF;
